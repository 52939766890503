<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Kunjungan Rumah
              </h5>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="nisn" rules="required">
                <b-form-group label="NISN" label-for="nisn" :state="errors.length > 0 ? false : null">
                  <v-select id="nisn" v-model="dataParent.nisn" :reduce="(nisn) => nisn.nisn" placeholder="Pilih Siswa"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa" label="name"
                    @input="onChange($event)" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="parent_id" rules="required">
                <b-form-group label="Orang Tua" label-for="parent_id" :state="errors.length > 0 ? false : null">
                  <v-select id="parent_id" v-model="dataParent.parent_id" :reduce="(parent_id) => parent_id.id"
                    placeholder="Pilih Orang Tua" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listParent" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
            <b-col md="6">
              <b-form-group label="Tanggal" label-for="date">
                <validation-provider #default="{ errors }" name="Tanggal" rules="required">
                  <b-form-input id="date" v-model="dataParent.date" type="date" :state="errors.length > 0 ? false : null"
                    placeholder="21-05-1992" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Identifikasi Masalah" label-for="identification">
                <validation-provider #default="{ errors }" name="identification" rules="required">
                  <b-form-input id="identification" v-model="dataParent.identification"
                    :state="errors.length > 0 ? false : null" placeholder="Identifikasi Masalah" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Tujuan Kunjungan" label-for="purpose">
                <validation-provider #default="{ errors }" name="purpose" rules="required">
                  <b-form-input id="purpose" v-model="dataParent.purpose" :state="errors.length > 0 ? false : null"
                    placeholder="Tujuan Kunjungan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Hasil Kunjungan" label-for="results">
                <validation-provider #default="{ errors }" name="results" rules="required">
                  <b-form-input id="results" v-model="dataParent.results" :state="errors.length > 0 ? false : null"
                    placeholder="Hasil Kunjungan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Kesimpulan" label-for="conclusion">
                <validation-provider #default="{ errors }" name="conclusion" rules="required">
                  <b-form-input id="conclusion" v-model="dataParent.conclusion" :state="errors.length > 0 ? false : null"
                    placeholder="Kesimpulan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Tindak Lanjut" label-for="follow_up">
                <validation-provider #default="{ errors }" name="follow_up" rules="required">
                  <b-form-input id="follow_up" v-model="dataParent.follow_up" :state="errors.length > 0 ? false : null"
                    placeholder="Tindak Lanjut" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="teacher_id" rules="required">
                <b-form-group label="Guru Yang menangani" label-for="teacher_id"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="teacher_id" v-model="dataParent.teacher_id" :reduce="(teacher_id) => teacher_id.id"
                    placeholder="Guru Yang menangani" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listGuru" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    vSelect,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      listSiswa: [],
      listGuru: [],
      listParent: [],
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        nisn: '',
        parent_id: '',
        date: '',
        identification: '',
        purpose: '',
        results: '',
        conclusion: '',
        follow_up: '',
        teacher_id: '',
      },
    }
  },
  computed: {

  },
  created() {
    this.getsiswa()
    this.getGuru()
  },
  methods: {
    async onChange(event) {
      try {
        const response = await this.$http.get(`/studentfamilies/student/${event}`)
        this.listParent = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getsiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getGuru() {
      try {
        const response = await this.$http.get('/employees')
        this.listGuru = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },

    async getParentbySiswa(nip) {
      try {
        const response = await this.$http.get(`/studentfamilies/student/${nip}`)
        this.listParent = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/studenthomevisits', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-kunjungan-rumah' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
 